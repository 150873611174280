// Import all of Bootstrap's JS
import * as bootstrap from 'bootstrap'


window.onload = (event) => {
  if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    document.body.setAttribute('data-bs-theme','dark');
    // document.querySelector('#lightModeDark').classList.add('d-none');
    // document.querySelector('#lightModeLight').classList.remove('d-none');
  } else {
    // document.querySelector('#lightModeDark').classList.remove('d-none');
    // document.querySelector('#lightModeLight').classList.add('d-none');
  }
};

